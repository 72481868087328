import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom';

import orderRoutes from 'views/orders/ordersRoutes';
import { authRoutes } from 'views/auth/routes';
//  import Dashboard from 'views/dashboard/Dashboard';
import { productRoutes } from 'views/products/productRoutes';
import { purchaseRoutes } from 'views/purchase/purchaseRoutes';
import { blogRoutes } from 'views/blog/routes';
import { categoriesRoutes } from 'views/categories/categories.routes';
import { brandsRoutes } from 'views/brands/brands.routes';
import { modelsRoutes } from 'views/models/model.router';
import { seoRoutes } from 'views/seo/router';
import { accountRoutes } from 'views/account/accountRoutes';
import { usersRoutes } from 'views/users/usersRoutes';
import { settingsRoutes } from 'views/settings/settingsRoutes';
import { dataRoutes } from 'views/data/dataRoutes';
import { discountCodesRoutes } from 'views/discount/discount.router';
import { pagesRoutes } from 'views/pages/pages.router';
import { landingPagesRoutes } from 'views/landing-pages/landingPages.router';
import { shoppingGuideRoutes } from 'views/guides/shoppingGuide.router';
import { faqsRoutes } from 'views/faqs/faqs.router';
import { testmonialsRoutes } from 'views/testmonials/testmonials.router';
import { servicesRoutes } from 'views/service/service.routes'


import LogoOnlyLayout from 'layouts/LogoOnlyLayout';

//  import { Layout } from 'layouts/Layout';

import Loadable from 'components/Loadable'



//  import Dashboard from 'views/dashboard/Dashboard'

import AuthGuard from 'guards/AuthGuard'
import GuestGuard from 'guards/GuestGuard'

// const  DashboardLayout = Loadable(lazy(() => import('layouts/dashboard')));
import  DashboardLayout from 'layouts/dashboard';
import { galleryRoutes } from 'views/gallery/gallery.router';
import { navigationRouter } from 'views/navigation/navigation.router';

const  AuthLayout = Loadable(lazy(() => import('layouts/AuthLayout')));

// pages
const Dashboard = Loadable(lazy(() => import('views/dashboard/Dashboard')))
const Page500 = Loadable(lazy(() => import('views/errors/Page500')));
const NotFound = Loadable(lazy(() => import('views/errors/Page404')));


export const AppRoutes = () => {
  return  useRoutes([
    {
      path: 'auth',
      // element: authenticated ? (
      //   <DashboardLayout />
      // ) : (
      //   <Navigate to="/auth/login" />
      // ),
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        ...authRoutes,
      ]
      
    },
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/dashboard' replace />, index: true },
        { path: '/dashboard', element: <Dashboard /> },
        ...orderRoutes,
        ...productRoutes,
        ...purchaseRoutes,
        ...blogRoutes,
        ...categoriesRoutes,
        ...brandsRoutes,
        ...modelsRoutes,
        ...seoRoutes,
        ...accountRoutes,
        ...usersRoutes,
        ...settingsRoutes,
        ...dataRoutes,
        ...discountCodesRoutes,
        ...pagesRoutes,
        ...landingPagesRoutes,
        ...shoppingGuideRoutes,
        ...faqsRoutes,
        ...testmonialsRoutes,
        ...galleryRoutes,
        ...navigationRouter,
        ...servicesRoutes
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
};
