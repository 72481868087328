// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/signup'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset'),
  verify: path(ROOTS_AUTH, '/verify'),
  newPassword: path(ROOTS_AUTH, '/new-password')
};

export const AUTH_PATHS = PATH_AUTH

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_APP = {
  root: path( ROOTS_DASHBOARD, '/'),
  management: {
    settings: path(ROOTS_DASHBOARD, '/settings'),
    systemData: path(ROOTS_DASHBOARD, '/data'),
    users: path(ROOTS_DASHBOARD, '/users'),
    categories: {
      all: path(ROOTS_DASHBOARD, '/categories'),
      create: path(ROOTS_DASHBOARD, '/categories/create'),
      details: path(ROOTS_DASHBOARD, '/categories/details/:id'),
      edit: path(ROOTS_DASHBOARD, '/categories/update/:id'),
    }
  },
  marketing: {
    landingPageDetails: path(ROOTS_DASHBOARD, '/landing-pages/:id'),
    landingPage: {
      edit: path(ROOTS_DASHBOARD, '/landing-pages/edit/:id'),
    },
    shoppingGuide: {
      guides: path(ROOTS_DASHBOARD, '/shopping-guides'),
      create: path(ROOTS_DASHBOARD, '/shopping-guides/create'),
      edit: path(ROOTS_DASHBOARD, '/shopping-guides/edit/:id'),
      details: path(ROOTS_DASHBOARD, '/shopping-guides/details/:id'),
      itemDetail: (id) => path(ROOTS_DASHBOARD, `/shopping-guides/details/${id}`),
    },
    faqs: {
      create: path(ROOTS_DASHBOARD, '/faqs/create'),
      edit: path(ROOTS_DASHBOARD, '/faqs/edit/:id'),
      details: path(ROOTS_DASHBOARD, '/faqs/details/:id'),
      list: path(ROOTS_DASHBOARD, '/faqs'),
    },
    customPages: {
      create: path(ROOTS_DASHBOARD, '/custom-pages/create'),
      edit: (id) => path(ROOTS_DASHBOARD, `/custom-pages/edit/${id}`),
      details: (id) => path(ROOTS_DASHBOARD, `/custom-pages/details/${id}`),
      root: path(ROOTS_DASHBOARD, '/custom-pages'),
    },
    testimonials: {
      create: path(ROOTS_DASHBOARD, '/testimonials/create'),
      edit: path(ROOTS_DASHBOARD, '/testimonials/edit/:id'),
      details: path(ROOTS_DASHBOARD, '/testimonials/details/:id'),
      list: path(ROOTS_DASHBOARD, '/testimonials'),
    }
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    products: {
      create: path(ROOTS_DASHBOARD, '/products/create'),
      edit: path(ROOTS_DASHBOARD, '/products/edit/:id'),
      details: path(ROOTS_DASHBOARD, '/products/details/:id'),
      all: path(ROOTS_DASHBOARD, '/products'),
      list: path(ROOTS_DASHBOARD, '/products/list'),
      featured: path(ROOTS_DASHBOARD, '/products/featured'),
      offers: path(ROOTS_DASHBOARD, '/products/offers'),
      outOfStock: path(ROOTS_DASHBOARD, '/products/out-of-stock'),
      reviews: path(ROOTS_DASHBOARD, '/products/reviews'),
      newReview: path(ROOTS_DASHBOARD, '/products/reviews/new'),
    },
    gallery: {
      root: path(ROOTS_DASHBOARD, '/gallery'),
      details: path(ROOTS_DASHBOARD, '/gallery/details/:id'),
      project: path(ROOTS_DASHBOARD, '/project/detail/:id'),

    },
    services: {
      root: path(ROOTS_DASHBOARD, '/services'),
      details: path(ROOTS_DASHBOARD, '/services/detail/:id'),

    }
    
  },
 
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/users/profile/:id'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/users'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/reece-chung/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  seo: {
    create: path(ROOTS_DASHBOARD, '/seo/create'),
    update: path(ROOTS_DASHBOARD, '/seo/edit/:id'),
    details: path(ROOTS_DASHBOARD, '/seo/details/:id'),
    
  }
};
export const AppPaths = PATH_APP;

export const PATH_DOCS = '#';
