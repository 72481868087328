import { useState, createContext, useEffect } from 'react';

import http from 'common/http';

export const FeatureContext = createContext();

export const FeatureContextProvider = (props) => {
  const [features, setFeatures] = useState([]);

  const initializeFeatures = async () => {
    const { data } = await http.get('/admin/feature-category');
    if (data.state) {
      setFeatures(data.features);
    }
  };

  const addFeature = (feature) => {
    setFeatures([...features, feature]);
  };

  useEffect(() => {
    initializeFeatures();
  }, []);

  const loadFeatures = (features) => {
    setFeatures(features);
  };

  const deleteFeature = (feature) => {
    setFeatures(features.filter((b) => b._id === feature._id));
  };

  return (
    <FeatureContext.Provider
      value={{ features, loadFeatures, deleteFeature, addFeature }}
    >
      {props.children}
    </FeatureContext.Provider>
  );
};