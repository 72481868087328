//  export const imageHost = 'https://storage.googleapis.com/electroliquor';

const { REACT_APP_API_URL, REACT_APP_IMAGE_HOST, REACT_APP_WEBSITE, REACT_APP_STORE_TYPE, REACT_APP_HAS_TYPE, REACT_APP_CLOUDINARY_IMAGE_HOST } =  process.env;
// const REACT_APP_API_URL =  'http://localhost:9100';

export const apiURL = REACT_APP_API_URL; //  'http://localhost:8000';
console.log({ apiURL })
export const website = REACT_APP_WEBSITE

export const imageHost = REACT_APP_IMAGE_HOST
export const cloudinaryImageHost = REACT_APP_CLOUDINARY_IMAGE_HOST

export const storeType = REACT_APP_STORE_TYPE ?? 'ecommerce'

export const hasType = (REACT_APP_HAS_TYPE ?? 'NO') === 'YES'

export const userRoles = ['admin', 'marketer', 'sales', 'manager']

// console.log({ REACT_APP_STORE_TYPE })

export const APP_CONFIG = {
    hasFlavor: storeType === 'liquor_store',
    hasAbv: storeType === 'liquor_store',
    storeType
}

export const isString = (val) => typeof val === 'string' || val instanceof String

export const makeImageUrl = (url, imagePath) => {
  
    if (!url) return url;


    if (!isString(url)) {
        return url.preview || url
    }

   return url?.includes('cloudinary') || url.includes('http') ? url : `${imageHost}${imagePath}/${url}`

}