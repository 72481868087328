import Loadable from 'components/Loadable';
import { lazy } from 'react';

import { AppPaths } from 'routes/paths'

const ServicePage = Loadable(lazy(()=> import('./Services')));
const ServiceDetailPage = Loadable(lazy(()=> import('./_pages/ServiceDetail')));


const _paths = AppPaths.general.services


export const servicesRoutes = [
    { path: _paths.root, element: <ServicePage /> },
    { path: _paths.details, element: <ServiceDetailPage /> },
];