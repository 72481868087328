import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Button, Card, CardContent, IconButton, Box, Stack} from '@mui/material';

import { Link, useParams } from 'react-router-dom'

import Page from 'components/Page'
import LoadingScreen from 'components/loading-screen'
import { usePageDetail } from 'hooks/pages/usePage'
import EditIcon from '@mui/icons-material/Edit';

import ProductsTable from '../../products/_components/ProductsTable';

import AddProductToCustomPage from '../_components/AddProductModal';
import { AppPaths } from 'routes/paths';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { renderContent } from 'common/methods';

import Image from 'components/_image/Image';
import { makeImageUrl } from 'common';

import http from 'common/http';
import { toast } from 'react-toastify';

const PageDetail = () => {

    const { id } = useParams();
    const { page, loading, msg, error, products, mutate } = usePageDetail(id);

    if (loading) {
        return (
            <div sx={{ isolation: 'isolated' }}>
                <LoadingScreen />
            </div>
        )
    }

    console.log(products, 'products')

    return (
        <Page title="Page details">
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <RenderPage error={error} page={page} loading={loading} msg={msg} products={products} mutate={mutate} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

const RenderPage = ({ page, loading, error, msg, products, mutate }) => {
    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return (<div>
            <h3>Error loading Data</h3>
            <p>{msg}</p>
        </div>)
    }

const handleChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return false;
    }
    // console.log( `/admin/page/banner/${page.id}`, 'page banner')
    const postData = new FormData();
    postData.append('banner', file);
    const { data } = await http.post(
      `/admin/page/banner/${page.id}`,
      postData
    );
    if (data.state) {
      mutate()
      toast.success('Collection image updated successfully')

    } else {
      toast.error('Failed to update collection image')
    }
    //  props.handleFile(fileUploaded);
  };

//   console.log(page)


    
    return (
        <>
           <Typography component="div" variant="h4" className="primary_color capitalize-first">
            <Grid container direction="row" justifyContent="space-between"  >
                <Grid item>
                    {page.name} 
                </Grid>
                <Grid item  gap={3}>
                    <Button m={4} component={Link} to={AppPaths.marketing.customPages.edit(page.id)} >
                        <EditIcon />
                    </Button>
                    {'  '}
                    <AddProductToCustomPage page={page} pageId={page.id} mutate={mutate} />
                </Grid>
            </Grid>
          </Typography>

            <Grid container gap={3} sx={{ mt: 5 }}>

                <Grid item xs={12}>

                <Box>
                      <Stack spacing={2.5} direction="column" alignItems="center">
                        {page.banner && 
                        
                        <Image
                          alt={page?.name}
                          src={makeImageUrl(page.banner, '/banner')}
                          sx={{ borderRadius: 1.5 }}
                          />}

                        <input
                          type="file"
                          placeholder='Collection banner'
                          sx={{ width: '100%' }}
                          // ref={inputRef}
                          onChange={handleChange}
                          // style={{ display: 'none' }}
                          name="logo"
                          accept="image/*"
                        />

                      </Stack>

                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>

                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                
                                <ListItemText primary="Name" secondary={page?.name} />
                            
                            </ListItem>

                           { page.brand && <ListItem>
                                
                                <ListItemText primary="Brand" secondary={page?.brand?.name} />
                            
                            </ListItem>
                            }

                           { page.category && <ListItem>
                                
                                <ListItemText primary="Category" secondary={page?.category?.name} />
                            
                            </ListItem>
                            }

                            <ListItem>
                                <ListItemText primary="Meta title" secondary={page?.metaTitle} />
                            </ListItem>

                            <ListItem>
                                <ListItemText primary="Meta description" secondary={page?.metaDescription} />
                            </ListItem>


                            <ListItem>
                                <ListItemText primary="Url" secondary={page?.url} />
                            </ListItem>

                            <ListItem>
                                <ListItemText primary="Page summary" secondary={page?.summary} />
                            </ListItem>
                    
                        </List>

                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>

                    <ProductsTable products={products} />

                </Grid>

                <Grid container gap={3} sx={{ mt: 5 }}>
                    <Grid item xs={12}>
                        <Card>
                                <CardContent >
                                    {renderContent(page.content)}
                                </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </Grid>
        
        </>
    );
}

export default PageDetail